import { makeStyles } from 'tss-react/mui';
import Typography from '@mui/material/Typography';
import ChevronRight from '@mui/icons-material/ChevronRight';
import GeoCoder from '../Header/Geocoder';
import BadgeJoinButton from '../Profile/BadgeJoinButton';
import Image from 'components/FutureImage';

const useStyles = makeStyles()(() => ({
  heading: {
    textShadow: '1px 1px 20px #343A40',
    fontSize: '4em',
  },
}));

export default function SearchJumbotron() {
  const { classes } = useStyles();
  return (
    <>
      <div className="tw-relative tw-full tw-h-[670px] -tw-mt-[65px] tw-flex">
        <Image
          src="https://img.travelfeed.io/bg-blog.jpg"
          priority
          alt="Travel Blog"
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAQAAAACCAIAAADwyuo0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAIklEQVR4nGMwdjZ6/f+bi60BDwsDQ08bZ3k1Q2ohg4kjAwB0FgeiS8FSFgAAAABJRU5ErkJggg=="
          sizes="100vw"
          className="tw-object-cover tw-object-center"
          fill
        />
        <div className="my-auto container text-center tw-z-10">
          <Typography
            variant="h2"
            component="h1"
            className={`tw-text-5xl font-weight-bold text-light ${classes.heading} pb-4`}
          >
            Travel Blogging for Everyone
          </Typography>
          <GeoCoder isMain />
          <BadgeJoinButton
            caption={
              <>
                <span className="mr-2">
                  Start your own travel blog for free
                </span>
                <ChevronRight />
              </>
            }
          />
        </div>
      </div>
    </>
  );
}
